<template>
  <div class="container p-5">
      <h1 class="text-black">{{$t('footerLink.cookies')}}</h1>
      <p class="text-black">
        {{ $t('cookiesSection.phrase_1') }}
        
        <ul class="pt-3">
            <li>{{ $t('email') }}: {{ emailOffice }} </li>
        </ul>
        
        {{ $t('cookiesSection.phrase_2') }}

        <span class="title py-2">{{ $t('cookiesSection.title_1') }}</span>
        {{ $t('cookiesSection.description_1') }}
        
        <span class="title py-2">{{ $t('cookiesSection.title_2') }}</span>
        {{ $t('cookiesSection.description_2') }}
        {{ $t('cookiesSection.description_3') }}
        {{ $t('cookiesSection.description_4') }}
        {{ $t('cookiesSection.description_5') }}
        <span class='bold'>{{ $t('ownCookies') }}</span>:
        
        <ul class="pt-3">
            <li>{{ $t('cookiesSection.ul_1_li_1')}}</li>
        </ul>
        {{ $t('cookiesSection.usesTheFollowing') }} <span class="bold">{{ $t('thirdPartyCookies') }}</span>:
        <ul class="pt-3">
            <li>{{ $t('cookiesSection.ul_1_li_2')}}</li>
        </ul>
        
        {{ $t('cookiesSection.description_6') }}
        
        <span class="title py-2">{{ $t('cookiesSection.title_3') }}</span>
        {{ $t('cookiesSection.description_7') }}
        {{ $t('cookiesSection.description_8') }}
      </p>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
            emailOffice: process.env.VUE_APP_OFFICE_EMAIL || ''
        }
    },
    computed: { ...mapState(["defaultTitle"]) },
    metaInfo() {
        return {
            title: `${this.$t('footerLink.cookies')} - ${this.defaultTitle}`,
            meta: [
                {name: 'og:title', property: 'og:title', content:  `${this.$t('footerLink.cookies')} - ${this.defaultTitle}`},
                {name: 'og:type', property: 'og:type', content: 'website'},
                {name: 'og:url', property: 'og:url', content: `${process.env.VUE_APP_URL.substring(0, process.env.VUE_APP_URL.length - 1)}${this.$route.fullPath}`},
                {name: 'og:description', property: 'og:description', content: this.$t('vueMeta.cookies.description')},
                {name: 'description', content: this.$t('vueMeta.cookies.description')},
                {name: 'og:site_name', property: 'og:site_name', content: process.env.VUE_APP_AS_NAME}
            ]
        }
    }
}
</script>

<style>
.title {
    font-weight: bold;
    display: block;
}
.bold {
    font-weight: bold;
}
</style>